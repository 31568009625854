import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'faqs',
    loadChildren: () =>
      import('./pages/faqs/faqs.module').then((m) => m.FaqsModule),
  },
  {
    path: 'charities',
    loadChildren: () =>
      import('./pages/charities/charities.module').then(
        (m) => m.CharitiesModule
      ),
  },
  {
    path: 'club',
    loadChildren: () =>
      import('./pages/blog/blog.module').then((m) => m.BlogModule),
  },
  // {
  //   path: 'events',
  //   loadChildren: () =>
  //     import('./pages/events/events.module').then((m) => m.EventsModule),
  // },
  {
    path: 'event',
    loadChildren: () =>
      import('./pages/events/events.module').then((m) => m.EventsModule),
  },

  {
    path: 'results',
    loadChildren: () =>
      import('./pages/results/results.module').then((m) => m.ResultsModule),
    // import('./pages/events/events.module').then((m) => m.EventsModule),

  },

  {
    path: 'distances',
    loadChildren: () =>
      import('./pages/categories/categories.module').then(
        (m) => m.CategoriesModule
      ),
  },
  {
    path: 'regions',
    loadChildren: () =>
      import('./pages/regions/regions.module').then((m) => m.RegionsModule),
  },
  {
    path: 'cities',
    loadChildren: () =>
      import('./pages/cities/cities.module').then((m) => m.CitiesModule),
  },
  {
    path: 'venues',
    loadChildren: () =>
      import('./pages/venues/venues.module').then((m) => m.VenuesModule),
  },
  // {
  //   path: 'combinations',
  //   loadChildren: () =>
  //     import('./pages/combinations/combinations.module').then(
  //       (m) => m.CombinationsModule
  //     ),
  // },
  {
    path: 'contact',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'team',
    loadChildren: () =>
      import('./pages/team/team.module').then((m) => m.TeamModule),
  },
  {
    path: 'injury-help',
    loadChildren: () =>
      import('./pages/injury-help/injury-help.module').then(
        (m) => m.InjuryHelpModule
      ),
  },
  {
    path: 'partner',
    loadChildren: () =>
      import('./pages/partner/partner.module').then((m) => m.PartnerModule),
  },
  {
    path: 'cart',
    loadChildren: () =>
      import('./pages/cart/cart.module').then((m) => m.CartModule),
  },
  {
    path: 'wishlist',
    loadChildren: () =>
      import('./pages/wishlist/wishlist.module').then((m) => m.WishlistModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'pacing-chart',
    loadChildren: () =>
      import('./pages/pacing-chart/pacing-chart.module').then(
        (m) => m.PacingChartModule
      ),
  },
  {
    path: 'volunteer',
    loadChildren: () =>
      import('./pages/volunteer/volunteer.module').then(
        (m) => m.VolunteerModule
      ),
  },
  {
    path: 'library',
    loadChildren: () =>
      import('./pages/library/library.module').then((m) => m.LibraryModule),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () =>
      import('./pages/terms-conditions/terms-conditions.module').then(
        (m) => m.TermsConditionsModule
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./pages/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: 'sustainability',
    loadChildren: () =>
      import('./pages/sustainability/sustainability.module').then(
        (m) => m.SustainabilityModule
      ),
  },
  {
    path: 'calendar',
    loadChildren: () =>
      import('./pages/calendar/calendar.module').then((m) => m.CalendarModule),
  },
  {
    path: 'corporate-events',
    loadChildren: () =>
      import('./pages/corporate-events/corporate-events.module').then(
        (m) => m.CorporateEventsModule
      ),
  },
  {
    path: 'seasonal-passes',
    loadChildren: () =>
      import('./pages/seasonal-passes/seasonal-passes.module').then(
        (m) => m.SeasonalPassesModule
      ),
  },
  {
    path: 'careers',
    loadChildren: () =>
      import('./pages/careers/careers.module').then((m) => m.CareersModule),
  },
  {
    path: 'alzheimers-research-uk',
    loadChildren: () =>
      import(
        './pages/alzheimers-research-uk/alzheimers-research-uk.module'
      ).then((m) => m.AlzheimersResearchUkModule),
  },
  // {
  //   path: 'redirect',
  //   loadChildren: () =>
  //     import('./pages/redirect/redirect.module').then((m) => m.RedirectModule),
  // },
  {
    path: 'error',
    loadChildren: () =>
      import('./pages/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/combinations/combination/combination.module').then(
        (m) => m.CombinationModule
      ),
  },
  // { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
