/**
 * This module is used to language translations.
 * The translations are saved in a json file in /src/app/assets/i18n directory
 * Docs: https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-angular7-app-with-ngx-translate
 */
import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { HttpClient } from "@angular/common/http";

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

// ngx-translate - required for AOT compilation
function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [TranslateModule]
})
export class TranslationModule {
  constructor(private translate: TranslateService) {
    // Gets Default language from browser if available, otherwise set English as default
    this.translate.addLangs([
      "en",
      "fr",
      "ur",
      "es",
      "it",
      "fa",
      "de",
      "zh-CHS"
    ]);

    this.translate.setDefaultLang("en");

    const browserLang = this.translate.getBrowserLang();

    this.translate.use(
      browserLang?.match(/en|fr|ur|es|it|fa|de|zh-CHS/) ? browserLang : "en"
    );
  }
}
