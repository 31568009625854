import { GoogleLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CountryCode } from 'libphonenumber-js';
import * as _ from 'lodash';
import Countries from 'src/assets/data/countries.json';
import { dialogEntities } from '../configs/dialog.config';
import { ColorOptions } from '../interfaces/colors.interface';
import { Country } from '../interfaces/country.interface';
import { CheckFileSizeProp } from '../interfaces/file.interface';
import { Photo, Video } from '../interfaces/media.interface';
import { HttpParams } from '@angular/common/http';
import { FAQ } from '../models/page.model';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root',
})
export class CoreService {

  constructor(
    private translateService: TranslateService,
    private router: Router,
    public sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) platformId: string,
    private dialogService: DialogService,
    private socialAuthService: SocialAuthService
  ) {
    this.isBrowser = isPlatformBrowser(platformId)
    this.isServer = isPlatformServer(platformId)
  }

  isBrowser = false
  isServer = true

  setSystemLanguage() {
    if (!this.isBrowser) return

    const storedLanguage = localStorage.getItem('LANGUAGE');

    if (storedLanguage && storedLanguage.length > 0) {
      return this.translateService.use(this.parseLanguage(storedLanguage));
    }

    const deviceLanguage = window.navigator.language;
    localStorage.setItem('LANGUAGE', deviceLanguage);

    return this.translateService.use(this.parseLanguage(deviceLanguage));
  }

  changeLanguage(language: string) {
    localStorage.setItem('LANGUAGE', language);
    this.translateService.use(this.parseLanguage(language));
  }

  parseLanguage(language: string): string {
    return language.split('-')[0];
  }

  removeDuplicateObjectByKey(array: any[] = [], key: string = "id") {
    return [...new Map(array.map(item => [item[key], item])).values()]
  }

  parseFaqs(faqs: FAQ[] = []): FAQ[] {
    const parsedFaqs = faqs?.map((faq: any) => {

      const faq_details = faq.faq_details.map((detail: any, index: number) => {
        const media = detail.uploads.map((upload: any) => upload.storage_url)

        return { ...detail, active: index == 0 ? true : false, media }
      })

      return { ...faq, faq_details }
    });

    return parsedFaqs ?? [];
  }

  getAccronym(value: any): string {
    let returnValue = '';
    if (!this.isEmptyOrNull(value)) {
      const stringValueArray = String(value).trim().split(' ');
      stringValueArray.forEach((item) => {
        returnValue += item.charAt(0).toUpperCase();
      });
      return returnValue;
    } else return returnValue;
  }

  /* Translate method */
  translate(key: string): string {
    let value = '';
    this.translateService.get(key).subscribe((data) => {
      value = data;
    });
    return value;
  }

  /* ROYGBIV method */
  getRandomColor(colorObject: ColorOptions, index?: number) {
    const values = Object.values(colorObject);
    if (index === undefined) {
      const prop = values[Math.floor(Math.random() * values.length)];
      return prop;
    } else return values[index] ? values[index] : values[0];
  }

  get _lodashRef() {
    return _;
  }

  getMonthsAhead() {
    const currentMonth = new Date().getMonth();
    const months = Array.from({ length: 12 }, (e, i) => {
      return new Date(0, i + 1, 0).toLocaleDateString('en', { month: 'long' });
    });

    return months.slice(currentMonth + 1);
  }

  sanitizedUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  detectBrowser(): string {
    const userAgent = navigator.userAgent;
    let browserName = '';

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = 'chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = 'firefox';
    } else if (userAgent.match(/safari/i)) {
      browserName = 'safari';
    } else if (userAgent.match(/opr\//i)) {
      browserName = 'opera';
    } else if (userAgent.match(/edg/i)) {
      browserName = 'edge';
    } else {
      browserName = 'No browser detection';
    }

    return browserName;
  }

  // test if a string value is null, undefined or empty
  isEmptyOrNull(value: string) {
    if (
      value == '' ||
      value == null ||
      value == undefined ||
      value == 'undefined'
    ) {
      return true;
    }
    return false;
  }

  /** Delete empty entries or trim entries
   * @param {any} input Request object
   * @param {any} blacklist Array of keys to delete NOTE use this only on edit/update requests
   */
  sanitiseRequestObject(
    input: any,
    blacklist: string[] = [],
    keyToDelete?: string,
    keyToTrim?: string,
    deleteInput?: boolean
  ) {
    Object.keys(input).forEach((key, index) => {
      if (blacklist.length > 0) {
        if (
          (typeof input[key] == 'string' || typeof input[key] == 'object') &&
          this.isEmptyOrNull(input[key]) && blacklist.includes(key)
        ) {
          delete input[key];
        }
      } else {
        if (
          (typeof input[key] == 'string' || typeof input[key] == 'object') &&
          this.isEmptyOrNull(input[key])
        ) {
          delete input[key];
        }
      }

      if (
        typeof input[key] == 'string' &&
        this.isEmptyOrNull(input[key]) &&
        deleteInput == true
      ) {
        Object.keys(input).splice(index, 1);
      }
      if (key == keyToTrim && typeof input[key] == 'string') {
        const trimmedValue = String(input[key]).replace(/\s/g, '');
        input[key] = trimmedValue;
      }
      if (key == keyToDelete) {
        delete input[key];
      }
    });
    return input;
  }

  getFileDataFromBase64(base64: string) {
    const fileData = base64.split(',')[1];
    return fileData;
  }

  getBase64FromBlob(blob: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (err) => reject(err);
    });
  }

  openUrl(url: string, target = '_blank'): void {
    window.open(url, target);
  }

  goBack(fallbackPath: string, redirectPath?: string) {
    if (redirectPath) this.router.navigate([redirectPath]);
    else if (history.length > 2) history.back();
    else this.router.navigate([fallbackPath]);
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  scrollTo(id: string): void {
    const el = document.getElementById(id);
    el && el.scrollIntoView({ behavior: 'smooth' });
  }

  checkFileSize({ fileList, maximumFileSize = 10, showAlert = true }: CheckFileSizeProp): { error: boolean } {
    let isTooSmall = false;
    const isTooLarge = Object.keys(fileList).find((_, index) => {
      const fileSize = fileList[index].size;
      const fileMb = fileSize / 1024 ** 2;

      if (fileMb === 0) {
        isTooSmall = true
      }

      if (fileMb > maximumFileSize) {
        return true;
      } else {
        return false;
      }
    });

    if (isTooSmall) {
      if (showAlert) {
        const singleText = `Please ensure the selected file exceeds 0 byte.`;
        const multiText = `Please ensure all of the selected files exceeds 0 byte.`;

        this.dialogService
          .commonDialog({
            data: { entity: dialogEntities.file, action: 'upload' },
            message: fileList.length > 1 ? multiText : singleText,
            type: 'warning',
          });
      }
      return { error: true }
    }

    if (!isTooSmall && isTooLarge) {
      if (showAlert) {
        const singleText = `Please ensure the selected file does not exceed ${maximumFileSize}MB.`;
        const multiText = `Please ensure none of the selected files exceeds ${maximumFileSize}MB.`;

        this.dialogService
          .commonDialog({
            data: { entity: dialogEntities.file, action: 'upload' },
            message: fileList.length > 1 ? multiText : singleText,
            type: 'warning',
          });
      }
    }

    return { error: !!isTooLarge };
  }

  addAddressesToEvents = (events: Array<any>) => {
    return events.map((a) => {
      const lat = a['address']?.latitude ?? null;
      const lng = a['address']?.longitude ?? null;
      return {
        ...a,
        address: { ...a['address'], lat: Number(lat), lng: Number(lng) },
      };
    });
  };

  constructGallery(property: any) {
    const gallery: Photo[] | Video[] = [];
    property?.map((m: any) => {
      if (m.storage_url) {
        gallery.push({
          ...m,
          src: m.storage_url,
        });
      }
    });

    return gallery;
  }

  ObjectToQueryParams(object: {}) {
    let params = new HttpParams();

    params = params.appendAll(object);

    return params.toString();
  }

  debounce<T extends (...args: any[]) => any>(func: T, delay: number) {
    let timeoutId: ReturnType<typeof setTimeout>;

    return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    } as T;
  }

  getFilterValidations(filters: any) {
    const keys = Object.keys(filters);
    const blacklist = ['take', 'page', 'skip'];
    const filteredKeys = keys.filter(key => !blacklist.includes(key))

    if (filteredKeys.length > 0) {
      return 'No events match the search!'
    } else {
      return ''
    }
  }

  getCountryFromCode(code: CountryCode | undefined | string, countries: Country[] = Countries as Country[]): Country {
    return countries.find(country => country.iso2 == code) as Country
  }

  getCountryByName(countryName: string, countries: Country[] = Countries as Country[]): Country | undefined {
    return countries.find(
      (country) => country.name.toLowerCase() === countryName.toLowerCase()
    );
  }

  observeDOMElementOnMount(selector: string, selectorType: 'tagName' | 'className' | 'id', callBack: (data: any) => void, targetNode = document.body) {
    const observerOptions = {
      childList: true,
      subtree: true,
    };

    function trackChanges(records: any, observer: MutationObserver) {
      for (const record of records) {
        for (const addedNode of record.addedNodes) {
          if (addedNode[selectorType] === (selectorType == 'tagName' ? selector.toUpperCase() : selector)) {
            callBack(addedNode);
            observer.disconnect();
          }
        }
      }
    }

    if (!this.isBrowser) return
    const observer = new MutationObserver(trackChanges);
    observer.observe(targetNode, observerOptions);
  }

  toggleCaptchaBadge(show: boolean) {
    if (!this.isBrowser) return
    const updateStyles = () => {
      const badge = document.getElementsByClassName('grecaptcha-badge')[0];

      if (badge && badge instanceof HTMLElement) {
        badge.style.visibility = show ? 'visible' : 'hidden';
        badge.style.bottom = show ? '14px' : '-60px';
      }
    }

    if (!show) return updateStyles()

    const badge = document.getElementsByClassName('grecaptcha-badge')[0];

    if (!badge) {
      this.observeDOMElementOnMount('grecaptcha-badge', 'className', updateStyles)
    } else updateStyles()
  }

  shouldScroll(data: any[], screenWidth: number, maxItem: number = 3, maxScreenSize: number = 1230) {
    if (screenWidth < maxScreenSize || data.length > maxItem) return true;
    else if (data.length < maxItem && screenWidth > maxScreenSize) return false;
    else return false
  }

  async formatSocialAuthData(user: SocialUser) {
    const authData: { [key: string]: any } = {}

    authData['email'] = user.email;
    authData['first_name'] = user.firstName;
    authData['last_name'] = user.lastName;

    if (user.provider == 'GOOGLE') {
      const accessToken = await this.socialAuthService.getAccessToken(GoogleLoginProvider.PROVIDER_ID)

      authData['social_auth'] = {
        source: user.provider.toLowerCase(),
        token: accessToken
      }
    }

    if (user.provider == 'FACEBOOK') {
      authData['social_auth'] = {
        source: user.provider.toLowerCase(),
        token: user.authToken
      }
    }
    return authData
  }
}
