import { createReducer, on } from '@ngrx/store';
import { globalSearchActions } from '../actions/globalSearch.actions';

type SearchResultType = any
type TabDataType = {
    events?: any,
    event_categories?: any,
    regions?: any,
    cities?: any,
    venues?: any,
    combinations?: any,
    pages?: any,
    charities?: any,
    recent: any
}

export type GlobalSearchType = {
    searchResult: SearchResultType,
    tabData: TabDataType
}
const initialState: GlobalSearchType = {
    searchResult: {},
    tabData: {
        recent: {}
    }
}

export type Tabs = 'events' |
    'event_categories' |
    'regions' |
    'cities' |
    'venues' |
    'combinations' |
    'pages' |
    'charities' |
    'recent'

export const globalSearchReducer = createReducer(initialState,
    on(globalSearchActions.setSearchResult, (state, { result }) => ({ ...state, searchResult: result })),
    on(globalSearchActions.setTabResult, ((state, { result, tabName }) => {
        const tabs = { ...state.tabData };
        tabs[tabName] = result;
        return { ...state, tabData: tabs }
    })),
    on(globalSearchActions.resetTabs, (state) => ({ ...state, tabData: { ...initialState.tabData, recent: state.tabData.recent } }))
);


