import { createAction, props } from '@ngrx/store';
import { Tabs } from '../reducers/globalSearch.reducer';

const setSearchResult = createAction('[Global Component] SetSearchResult',
    props<{ result: any }>()
);

const setTabResult = createAction('[Global Component] SetTabResult',
    props<{ result: any, tabName: Tabs }>()
);

const resetTabs = createAction('[Global Component] resetTabs');

export const globalSearchActions = {
    setSearchResult,
    setTabResult,
    resetTabs
}

