import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, StateKey, TransferState, makeStateKey } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TransferStateService {
  public isServer = false;

  constructor(
    private transferState: TransferState,
    @Inject(PLATFORM_ID) platformId: object,
  ) {
    this.isServer = isPlatformServer(platformId);
  }

  set(key: StateKey<string>, value: any) {
    this.transferState.set(key, value);
  }

  get(key: StateKey<string>, defaultValue: any = null) {
    return this.transferState.get(key, defaultValue);
  }

  remove(key: StateKey<string>) {
    this.transferState.remove(key);
  }

  hasKey(key: StateKey<string>) {
    return this.transferState.hasKey(key);
  }

  getDynamicStateKey(key: string): StateKey<string> {
    return makeStateKey(key);
  }
}
