import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, StateKey } from '@angular/core';
import { apiConfig } from 'src/app/configs/api.config';
import { TransferStateService } from '../transfer-state.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    protected http: HttpClient,
    private transferStateService: TransferStateService
  ) {}

  makeGetRequest(
    url: string,
    stateKey?: StateKey<string>,
    options = apiConfig.options
  ) {
    return new Promise<any>((resolve, reject) => {
      if (stateKey && this.transferStateService.hasKey(stateKey)) {
        resolve(this.transferStateService.get(stateKey, {}));
        this.transferStateService.remove(stateKey);
      } else {
        this.http.get<any>(url, options).subscribe({
          next: (res) => {
            if (stateKey && this.transferStateService.isServer) {
              this.transferStateService.set(stateKey, res);
            }
            resolve(res);
          },
          error: (err) => {
            reject(err);
          },
        });
      }
    });
  }

  makePostRequest(
    url: string,
    body: any,
    stateKey?: StateKey<string>,
    options = apiConfig.options
  ) {
    return new Promise<any>((resolve, reject) => {
      if (stateKey && this.transferStateService.hasKey(stateKey)) {
        this.transferStateService.remove(stateKey);
        resolve(this.transferStateService.get(stateKey, {}));
      } else {
        this.http.post<any>(url, body, options).subscribe({
          next: (res) => {
            if (stateKey && this.transferStateService.isServer) {
              this.transferStateService.set(stateKey, res);
            }
            resolve(res);
          },
          error: (err) => {
            reject(err);
          },
        });
      }
    });
  }

  makePutRequest(url: string, body: any, options = apiConfig.options) {
    return new Promise<any>((resolve, reject) => {
      this.http.put<any>(url, body, options).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  makePatchRequest(url: string, body: any, options = apiConfig.options) {
    return new Promise<any>((resolve, reject) => {
      this.http.patch<any>(url, body, options).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  makeDeleteRequest(
    url: string,
    body?: any,
    options: { headers: HttpHeaders; body?: any } = apiConfig.options
  ) {
    options.body = body;
    return new Promise<any>((resolve, reject) => {
      this.http.delete<any>(url, options).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  makeOptionsRequest(url: string, options = apiConfig.options) {
    return new Promise<any>((resolve, reject) => {
      this.http.options<any>(url, options).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }

  makeFileRequest(url: string, options = apiConfig.options) {
    return new Promise<any>((resolve, reject) => {
      this.http
        .get(url, { ...options, observe: 'response', responseType: 'blob' })
        .subscribe({
          next: (res) => {
            resolve(res.body);
          },
          error: (err) => {
            reject(err);
          },
        });
    });
  }

  makeRedirectRequest(
    url: string,
    stateKey?: StateKey<string>,
    options = apiConfig.options
  ) {
    return new Promise<any>((resolve, reject) => {
      if (stateKey && this.transferStateService.hasKey(stateKey)) {
        resolve(this.transferStateService.get(stateKey, {}));
        this.transferStateService.remove(stateKey);
      } else {
        this.http
          .get(url, { ...options, observe: 'response', responseType: 'text' })
          .subscribe({
            next: (res) => {
              if (stateKey && this.transferStateService.isServer) {
                this.transferStateService.set(stateKey, res);
              }
              if (res.headers.get('content-type') == 'application/json') {
                resolve(JSON.parse(res.body ?? ''));
              } else resolve(res);
            },
            error: (err) => {
              reject(err);
            },
          });
      }
    });
  }

  makePostRedirectRequest(
    url: string,
    body: any,
    stateKey?: StateKey<string>,
    options = apiConfig.options
  ) {
    return new Promise<any>((resolve, reject) => {
      if (stateKey && this.transferStateService.hasKey(stateKey)) {
        this.transferStateService.remove(stateKey);
        resolve(this.transferStateService.get(stateKey, {}));
      } else {
        this.http.post(url, body, { ...options, observe: 'response', responseType: 'text' }).subscribe({
          next: (res) => {
            if (stateKey && this.transferStateService.isServer) {
              this.transferStateService.set(stateKey, res);
            }
            if (res.headers.get('content-type') == 'application/json') {
              resolve(JSON.parse(res.body ?? ''));
            } else resolve(res);
          },
          error: (err) => {
            reject(err);
          },
        });
      }
    });
  }
}
