import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { APP_ID, NgModule, } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { FacebookLoginProvider, GoogleInitOptions, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from '@abacritt/angularx-social-login';
import { DatePipe, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { NgxLoadingModule } from 'ngx-loading';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { appConfig } from './configs/app.config';
import { TranslationModule } from './modules/translation/translation.module';
import { ErrorInterceptorService } from './services/error-interceptor.service';
import { JwtInterceptorService } from './services/jwt-interceptor.service';
import { globalReducers } from './store/reducers';
import { ThemeModule } from './theme/theme.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    TranslationModule,
    NgbModule,
    ThemeModule,
    AngularSvgIconModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    RecaptchaV3Module,
    NgxLoadingModule.forRoot({
      primaryColour: 'var(--neutral-11)',
      secondaryColour: 'var(--primary-01)',
      backdropBorderRadius: '3px',
      fullScreenBackdrop: true,
    }),
    StoreModule.forRoot(globalReducers),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    SocialLoginModule,
  ],
  providers: [
    DatePipe,
    TitleCasePipe,
    UpperCasePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey },
    { provide: APP_ID, useValue: 'serverApp' },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(appConfig.socials.google.clientKey, appConfig.socials.google.configOptions as GoogleInitOptions)
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(appConfig.socials.facebook.clientKey)
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    provideHttpClient(withFetch())
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
