import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
import { ThemeService } from 'src/app/theme/theme.service';
//import { Router, NavigationEnd } from '@angular/router';
//import { filter } from 'rxjs/operators';
//import { googleConfig } from './configs/google.config';
import { scrollbarStyle } from 'src/assets/constants';
import { AuthService } from './services/api/auth.service';

//declare let gtag: (...rest: any) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'runthrough';

  constructor(
    private themeService: ThemeService,
    private coreService: CoreService,
    private authService: AuthService,
    //private router: Router
  ) { }

  ngOnInit(): void {
    this.themeService.getSystemTheme();
    this.coreService.setSystemLanguage();
    this.setPlatformUser();

    const navigator: any = window.navigator
    if (navigator['userAgentData']?.platform !== 'macOS') {
      const scrollBarCssFile = document.createElement('style');
      scrollBarCssFile.textContent = scrollbarStyle
      document.head.appendChild(scrollBarCssFile)
    }

    /*if (googleConfig.measurementId) {
      // set up google analytics if the measurement id has been properly configured for the project (runtime environment)
      this.setupGoogleAnalytics();
    }*/
  }

  /*setupGoogleAnalytics() {
    if (!this.coreService.isBrowser) return
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        gtag('config', googleConfig.measurementId, {
          page_path: event.urlAfterRedirects,
        });
      });
  }*/

  setPlatformUser() {
    if (!this.coreService.isBrowser) return
    window.addEventListener('storage', (event: StorageEvent) => {
      if (event.storageArea?.getItem('platform') == null)
        this.generateIdentifier();
    });

    if (this.coreService.isEmptyOrNull(this.authService.platformUser)) {
      this.generateIdentifier();
    }
  }

  generateIdentifier() {
    this.authService
      .generateIdentifier()
      .then((response) => {
        const user = { identifier: response.data.identifier };
        this.authService.platformUser = { user };
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
