import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';

export const apiConfig = {
    base: `${environment.api.base}`,
    client: `${environment.api.client}`,
    portal: `${environment.api.portal}`,
    options: {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
            "Accept": "application/json",
        })
    }
};
