import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {
  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
  ) { }

 executeRecaptchaV3(action: string): Promise<any> {
   return new Promise<any>((resolve, reject) => {
    this.recaptchaV3Service.execute(action).subscribe({
      next: (token) => {
        resolve(token);
      },
      error: (err) => {
        reject(err);
      },
    });
   })
  }
}
