import { Injectable } from '@angular/core';
import { apiConfig } from 'src/app/configs/api.config';
import { CoreService } from '../core.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private core: CoreService, private apiService: ApiService) { }

  /** GET: Fetch current user details
   */
  getCurrentUser(): Promise<any> {
    const url = `${apiConfig.base}/users/current`;

    //caution: passing the options invalidates the form data
    return this.apiService.makeGetRequest(url);
  }

  /** PATCH: Switch active role
   * @param {string} userRef reference code of the user
   * @param {any} requestBody Request bodys
   */
  switchActiveRole(userRef: string, requestBody: any): Promise<any> {
    const url = `${apiConfig.portal}/users/${userRef}/switch-active-role`;

    const body: any = requestBody;

    //caution: passing the options invalidates the form data
    return this.apiService.makePatchRequest(url, body);
  }
}
