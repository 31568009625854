// To parse this data:
//
//   import { Convert, Login } from "./file";
//
//   const login = Convert.toLogin(json);

import { User } from './user.model';

export interface Login {
  status: boolean;
  message: string;
  data: Data;
}

interface Data {
  user: User;
  token: string;
  social_event_type: string
}

// Converts JSON strings to/from your types
export class Convert {
  public static toLogin(json: string): Login {
    return JSON.parse(json);
  }

  public static loginToJson(value: Login): string {
    return JSON.stringify(value);
  }
}
