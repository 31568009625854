export const dialogConfig = [
  { singular: 'user', plural: 'users' },
  { singular: 'event', plural: 'events' },
  { singular: 'distance', plural: 'distances' },
  { singular: 'region', plural: 'regions' },
  { singular: 'experience', plural: 'experiences' },
  { singular: 'city', plural: 'cities' },
  { singular: 'venue', plural: 'venues' },
  { singular: 'page', plural: 'pages' },
  { singular: 'partner', plural: 'partners' },
  { singular: 'participant', plural: 'participants' },
  { singular: 'FAQ', plural: 'FAQs' },
  { singular: 'charity', plural: 'charities' },
  { singular: 'payment', plural: 'payments' },
  { singular: 'password', plural: 'passwords' },
  { singular: 'reset code', plural: 'reset codes' },
  { singular: 'cart', plural: 'carts' },
  { singular: 'wishlist', plural: 'wishlists' },
  { singular: 'combination', plural: 'combinations' },
  { singular: 'enquiry', plural: 'enquiries' },
  { singular: 'charity category', plural: 'charity categories' },
  { singular: 'file', plural: 'files' },
  { singular: 'role', plural: 'roles' },
  { singular: 'checkout', plural: 'checkouts' },
];

export const dialogEntities = {
  user: 'user',
  event: 'event',
  category: 'distance',
  region: 'region',
  experience: 'experience',
  city: 'city',
  venue: 'venue',
  page: 'page',
  partner: 'partner',
  participant: 'participant',
  FAQ: 'FAQ',
  charity: 'charity',
  payment: 'payment',
  password: 'password',
  resetCode: 'reset code',
  cart: 'cart',
  wishlist: 'wishlist',
  combination: 'combination',
  enquiry: 'enquiry',
  charityCategory: 'charity category',
  file: 'file',
  role: 'role',
  checkout: 'checkout',
};
