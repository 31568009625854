import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { dialogConfig } from '../configs/dialog.config';
import { Dialog, DialogData, EntityData } from '../interfaces/dialog.interface';

import { SweetAlert } from 'sweetalert/typings/core';
const swal: SweetAlert = require('sweetalert');

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private defaultButtons = {
    cancel: {
      text: 'Cancel',
      value: null,
      visible: true,
      closeModal: true,
    },
    confirm: {
      text: `OK`,
      value: true,
      visible: true,
      closeModal: false,
    },
  };
  constructor(private titleCase: TitleCasePipe) { }

  /**
   * Show most commonly displayed alerts
   * @param {string} confirmData Data for the alert
   */

  commonDialog(confirmData: Dialog) {
    return new Promise((resolve) => {
      swal({
        title: confirmData.data
          ? this.commonDialogData(confirmData.data).title
          : confirmData.title,
        text: confirmData.message
          ? confirmData.message
          : confirmData.data
            ? this.commonDialogData(confirmData.data).message
            : '',
        icon: confirmData.type,
        closeOnClickOutside: !confirmData.fixed,
        closeOnEsc: !confirmData.fixed,
        buttons: confirmData.customButtons,
      }).then((response) => {
        if (response) {
          resolve(response);
        }

        // There is no need to reject the promise as this swal does not send an error
        // else {
        //   reject(response);
        // }
      });
    });
  }

  /**
   * Show most confirmation alerts
   * @param {string} confirmData Data for the alert
   */
  confirmDialog(confirmData: Dialog) {
    return new Promise((resolve) => {
      swal({
        title: confirmData.data
          ? this.confirmDialogData(confirmData.data).title
          : confirmData.title,
        text: confirmData.message
          ? confirmData.message
          : confirmData.data
            ? this.confirmDialogData(confirmData.data).message
            : '',
        icon: 'warning',
        closeOnClickOutside: !confirmData.fixed,
        closeOnEsc: !confirmData.fixed,
        dangerMode: true,
        buttons: confirmData.customButtons ?? this.defaultButtons,
      }).then((response) => {
        if (response) {
          resolve(response);
        }

        // There is no need to reject the promise as this swal does not send an error
        // else {
        //   reject(response);
        // }
      });
    });
  }

  /**
   * Close the alert
   */
  closeDialog() {
    swal.close?.();
  }

  private commonDialogData(dialogData: DialogData) {
    let title = '';
    let message = '';

    switch (dialogData.action) {
      case 'fetch':
        title = `${this.titleCase.transform(
          dialogData.action
        )} ${this.titleCase.transform(
          this.getDialogConfig(dialogData.entity)?.singular
        )} Data`;
        break;
      case 'fetchMany':
        title = `Fetch ${this.titleCase.transform(
          this.getDialogConfig(dialogData.entity)?.plural
        )}`;
        break;
      case 'addTo':
        title = `Add to ${typeof dialogData.entity != 'string'
          ? this.titleCase.transform(dialogData.entity.property)
          : ''
          }`;
        message = `The ${this.getDialogConfig(dialogData.entity)?.singular
          } was successfully added to the ${typeof dialogData.entity != 'string' ? dialogData.entity.property : ''
          }.`;
        break;
      case 'removeFrom':
        title = `Remove From ${typeof dialogData.entity != 'string'
          ? this.titleCase.transform(dialogData.entity.property)
          : ''
          }`;
        message = `The ${this.getDialogConfig(dialogData.entity)?.singular
          } was successfully removed from the ${typeof dialogData.entity != 'string' ? dialogData.entity.property : ''
          }.`;
        break;
      case 'registerFor':
        title = `Register for ${this.titleCase.transform(
          this.getDialogConfig(dialogData.entity)?.singular
        )}`;
        break;
      case 'switch':
        title = `${this.titleCase.transform(dialogData.action)} ${typeof dialogData.entity != 'string'
          ? this.titleCase.transform(dialogData.entity.property) + ' ' + this.titleCase.transform(dialogData.entity.name)
          : ''
          }`;
        break;
      default: // create, edit, delete, restore, others
        {
          const evalQuantity = dialogData.quantity
            ? dialogData.quantity > 1
            : false;
          title = `${this.titleCase.transform(dialogData.action)} ${evalQuantity
            ? this.titleCase.transform(
              this.getDialogConfig(dialogData.entity)?.plural
            )
            : this.titleCase.transform(
              this.getDialogConfig(dialogData.entity)?.singular
            )
            }`;
        }
        break;
    }

    return {
      title,
      message,
    };
  }

  private confirmDialogData(dialogData: DialogData) {
    const evalQuantity = dialogData.quantity ? dialogData.quantity > 1 : false;
    const title = 'Are you sure?';
    let message = '';

    switch (dialogData.action) {
      case 'removeFrom':
        message = `You want to remove ${evalQuantity
          ? `these ${this.getDialogConfig(dialogData.entity)?.plural ?? ''}`
          : `this ${this.getDialogConfig(dialogData.entity)?.singular ?? ''}`
          } from the ${typeof dialogData.entity != 'string' ? dialogData.entity.property : ''
          }.`;
        break;
      default: // create, edit, delete, restore, others
        message = `You want to ${dialogData.action} ${evalQuantity
          ? `these ${this.getDialogConfig(dialogData.entity)?.plural ?? ''}`
          : `this ${this.getDialogConfig(dialogData.entity)?.singular ?? ''}`
          }.`;
        break;
    }

    return {
      title,
      message,
    };
  }

  private getDialogConfig(entity: string | EntityData) {
    if (typeof entity === 'string')
      return dialogConfig.find((config) => config.singular == entity);
    else return dialogConfig.find((config) => config.singular == entity.name);
  }
}
