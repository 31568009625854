/**
 * The Error Interceptor intercepts http responses from the api to check if there were any errors. If there is a 401 Unauthorized response
 * the user is automatically logged out of the application, all other errors are re-thrown to be caught by the calling service so an alert
 * can be displayed to the user.
 */

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { AuthService } from './api/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((resp) => {
        if (resp instanceof HttpResponse) {
          if (resp.headers.get('content-type') == 'text/html') {
            // here you can implement your logic, or simply redirect as below
            const redirectUrl = resp.url;
            if (redirectUrl?.includes(location.origin)) {
              this.router.navigate([new URL(redirectUrl).pathname]);
            } else location.replace(redirectUrl ?? '');
          }
        }
      }),
      catchError((err: HttpErrorResponse) => {
        let formattedError = err.error;

        // If the error is as a result of a redirect endpoint
        if (typeof err.error == 'string') {
          formattedError = JSON.parse(err.error);
        }

        // Add Status Code to the error object
        formattedError.statusCode = err.status;
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authService.onLogout();
          location.href = '/auth/login';
        } else if (err.status === 0) {
          // Define the error message if none is defined
          return throwError(
            () =>
              new Error('An unknown error occurred. Please try again later.')
          );
        }

        return throwError(() => formattedError);
      })
    );
  }
}
