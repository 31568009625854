import { Injectable } from '@angular/core';
import { Theme, themePalette, Palette, themeType } from './theme.interface';
import { themes } from './themes';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private activeThemePalette: themePalette;
  private activeThemeType: themeType;
  private activePalette: Palette;
  public themeTypeSubject: Subject<string> = new Subject<string>();

  constructor() {
    const index = themes.findIndex(
      (t) => Object.keys(t)[0] === environment.palette
    );

    if (index > -1) {
      this.activeThemePalette = themes[index][environment.palette];
    } else {
      this.activeThemePalette = themes[0]['rfc'];
    }

    this.activeThemeType = 'dark';
    this.themeTypeSubject.next(this.activeThemeType);
    this.activePalette = this.activeThemePalette[this.activeThemeType];
    this.getSystemTheme();
  }

  getSystemTheme() {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      const theme = localStorage.getItem('THEME');
      if (theme && theme !== 'dark') {
        return this.setLightThemePalette();
      } else if (theme === 'dark') {
        return this.setDarkThemePalette();
      }

      localStorage.setItem('THEME', 'dark');
      this.setDarkThemePalette();
    }

    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: light)').matches
    ) {
      const theme = localStorage.getItem('THEME');
      if (theme && theme !== 'light') {
        return this.setDarkThemePalette();
      } else if (theme === 'light') {
        return this.setLightThemePalette();
      }

      localStorage.setItem('THEME', 'light');
      this.setLightThemePalette();
    }
  }

  getThemes(): Array<Theme> {
    return themes;
  }

  getActiveThemeType(): themeType {
    return this.activeThemeType;
  }

  getActivePalette(): Palette {
    return this.activePalette;
  }

  isLightTheme(): boolean {
    return this.activeThemeType === 'light';
  }

  isDarkTheme(): boolean {
    return this.activeThemeType === 'dark';
  }

  setDarkThemePalette(): void {
    this.activeThemeType = 'dark';
    localStorage.setItem('THEME', 'dark');
    this.setActiveThemePalette(this.activeThemePalette[this.activeThemeType]);
    this.themeTypeSubject.next(this.activeThemeType);
  }

  setLightThemePalette(): void {
    this.activeThemeType = 'light';
    localStorage.setItem('THEME', 'light');
    this.setActiveThemePalette(this.activeThemePalette[this.activeThemeType]);
    this.themeTypeSubject.next(this.activeThemeType);
  }

  setActiveThemePalette(palette: Palette): void {
    this.activePalette = palette;
    Object.keys(this.activePalette).forEach((property) => {
      document.documentElement.style.setProperty(
        property,
        this.activePalette[property as keyof Palette]
      );
    });
  }

  onThemeChanged(): Observable<string> {
    return this.themeTypeSubject.asObservable();
  }
}
