/**
 * The JWT Interceptor intercepts http requests from the application to add a JWT auth token to the
 * Authorization header if the user is logged in.
 */

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { appConfig } from '../configs/app.config';
import { dialogEntities } from '../configs/dialog.config';
import { AuthService } from './api/auth.service';
import { UserService } from './api/user.service';
import { CoreService } from './core.service';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root',
})
export class JwtInterceptorService implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private coreService: CoreService,
    private dialogService: DialogService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add client key. Used the the api to understand the client making the request
    request = request.clone({
      setHeaders: {
        'X-Client-Key': `${appConfig.client}`,
        'X-Platform-User-Identifier-Key': this.authService.platformUser,
      },
    });

    // add authorization header with jwt token if available
    const loginData = this.authService.loginData;

    if (loginData && loginData?.data && loginData?.data?.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${loginData.data?.token}`,
        },
      });
    }

    if (
      this.coreService.isBrowser &&
      loginData &&
      !loginData?.data?.user?.is_verified &&
      !(
        location.pathname == '/auth/login' ||
        location.pathname == '/auth/verify' ||
        location.pathname == '/auth/register' ||
        request.url.includes('/users/current')
      )
    ) {
      this.dialogService
        .confirmDialog({
          title: 'Verify Your Account',
          message: 'Please enter the verification code we sent to you earlier.',
          customButtons: {
            cancel: {
              text: 'Cancel',
              value: null,
              visible: true,
              closeModal: true,
            },
            confirm: {
              text: 'Verify',
              value: true,
              visible: true,
              closeModal: false,
            },
          },
        })
        .then(async () => {
          try {
            const user = await this.userService.getCurrentUser();
            this.dialogService.closeDialog();
            const tempData = this.authService.loginData;
            tempData.data.user = user.data.user;
            this.authService.loginData = tempData;

            if (!tempData.data.user.is_verified) {
              this.router.navigate(['/auth/verify'], {
                queryParams: { returnUrl: this.router.url },
              });
            } else {
              this.dialogService.commonDialog({
                title: 'Verification Status',
                message: 'You have already been verified.',
                type: 'info',
              });
            }
          } catch (error: any) {
            console.error('getCurrentUser: ', error);
            this.dialogService.commonDialog({
              data: { entity: dialogEntities.user, action: 'fetch' },
              message: error?.message,
              type: 'error',
            });
          }
        });
    }

    return next.handle(request);
  }
}
